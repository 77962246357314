@media screen and (max-width:700px ){
    .navbar{
        font-size:13px;
    }
    .main{
        margin:0;
        width:50%;
        font-size:15px;
    }
    .current-kids-div{
        display: flex;
        flex-flow: column;
        justify-content: space-around;
        height: 600px;
        width:90%;
        padding: 30px;
        margin-right: 300px;
    }
    .currentKids{
        display: flex;
        flex-flow:column ;
        width: 60%;
    }
    .buttons-div{
        margin-top:20px;
    }
    h2{
        text-align: center;
        font-size: small;
    }
    ul{
        margin: 30px;
    }
   
}
@media screen and (max-width:500px ){
            
    .main{
        width:75%;
        font-size:12px;
        margin: 10px 0 0 50px;
    }
    .currentKids{
        font-size: 12px;
        margin-right:12px;
        
    }
    .current-kids-div{
        height:300px;
    }
    .chore-description{
        padding: 10px;
    }
    .chore-card-daily{
        transition:0.4s cubic-bezier(0, 0, 0.04, 0.97);
    }
    .chore-card-daily:hover{
        transform:scale(1.2);
    }
    #name{
        font-size: 10px;
       
    }
    
    .chore-div{
        width:100%;
    }
    #title{
        font-size:13px;
    }
    #name{
        font-size:15px;
    }
}

    @media screen and (max-width:300px ){
        .main{
            width:75%;
            font-size:12px;
            margin: 10px 0 0 50px;
        }
        .currentKids{
            font-size: 12px;
            margin-right:12px;
            
        }
        #name{
            font-size: 10px;
            margin-right: 5px;
        }
        .chore-description{
            font-size:12px;
        }
        .chore-div{
            width:280px;
        }
        #title{
            font-size:13px;
        }
        #name{
            font-size:15px;
        }

    }
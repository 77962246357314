 @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');
 @import url('https://fonts.googleapis.com/css2?family=REM:wght@200&display=swap');



body{
    font-family: 'Roboto', sans-serif;
    margin: 0;
    color: white;
    background-color: skyblue;
    font-size: 20px;
}
.navbar{
    display: flex;
    flex-flow: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    box-shadow: -2px 24px 36px -2px rgba(0, 0, 0, 0.56);
    -webkit-box-shadow:-2px 24px 36px -2px rgba(0, 0, 0, 0.56);
    -moz-box-shadow:-2px 24px 36px -2px rgba(0, 0, 0, 0.56);
    border-bottom: 1px solid black;
    border-radius: 10px; 
}
.main{
    display: flex;
    flex-flow: column;
    align-items: center;
    width: 75%;
    height: 500px;
    margin-top: 100px;
    font-family: 'Montserrat', sans-serif;
    background-color: white;
    color: black;
    border-radius: 5px;
    margin-left: 160px;
}
.premise{
    display:flex;
    flex-flow: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height: 100%;
}

#top{
    position: relative;
    bottom: 50px;
    padding: 5px;
}
#bottom{
    position: relative;
    top:50px;
    padding:5px;
}

.currentKids{
    display: flex;
    flex-flow: row;
    justify-content: space-evenly;
    align-items: center;
    width: 50%;
    height:100%;
    background-color: navy;
    margin-top: 50px;
    box-shadow: -2px 24px 36px -2px rgba(0, 0, 0, 0.56);
    -webkit-box-shadow:-2px 24px 36px -2px rgba(0, 0, 0, 0.56);
    -moz-box-shadow:-2px 24px 36px -2px rgba(0, 0, 0, 0.56);
    border-bottom: 1px solid black;
    border-radius: 10px;
    
}


.current-kids-div{
    display: flex;
    flex-flow: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height:auto;
    
}
.buttons-div{
    display: flex;
    flex-flow: row;
    justify-content: space-evenly;
    align-items: center;
    width:50%;

}
.button{
    padding:5px;
    background-color:#FFFFFF;
    font-size: 20px;
    border-radius:10px;
    font-family: 'Times New Roman', Times, serif;
    transition: 0.4s;
}
.button:hover{
    background-color: black;
    color:white;
    transform: scale(0.9);
    cursor: pointer;
}

.chore-div{
    display: flex;
    flex-flow: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height:100%;
    background-color: yellow;
    color:black;
    margin: 10px 0px 10px 0px;
    border-radius: 10px;
}
.chore-description{
    display: flex;
    flex-flow:column;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    width:50%;
    font-family: 'REM', sans-serif;
    transition: 0.4s cubic-bezier(0, 0, 0.04, 0.97);;
}
.chore-description:hover{
    box-shadow: -2px 24px 36px -2px rgba(0, 0, 0, 0.56);
    -webkit-box-shadow:-2px 24px 36px -2px rgba(0, 0, 0, 0.56);
    -moz-box-shadow:-2px 24px 36px -2px rgba(0, 0, 0, 0.56);
    border-radius: 10px;
}
#dish{
    background-color: green;
    color:rgb(231, 255, 14);
}


#title{
    font-size: 25px;
    text-decoration: underline;
}ul {
  width:100%
}


/* loading from loading.io/css */
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

